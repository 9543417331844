<template>
  <div class="main">
    <div class="page-header">
      <img
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_HaiLuo/header.png"
        alt="顶部图片"
      />
    </div>

    <div class="page-content">
      <div class="page-slice">
        <img
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_HaiLuo/content-slice-left.png"
          alt="左箭头"
        />
        <span>本月可领取的会员权益</span>
        <img
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_HaiLuo/content-slice-right.png"
          alt="右箭头"
        />
      </div>

      <div class="page-fuli1">
        <div class="fuli1-title">
          <img
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_HaiLuo/fuli1-title.png"
            alt="福利一标题"
          />
        </div>
        <div class="fuli1-list">
          <div
            class="item"
            v-for="item in firstList"
            :key="item.id"
            @click="claimItem('first', item)"
          >
            <img
              class="bgImg"
              src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_HaiLuo/item-bg.png"
              :alt="item.alt"
            />

            <!-- 已经领取的权益应用的样式 -->
            <img
              v-if="
                exchangedItems &&
                exchangedItems.includes(item.item_name) &&
                is_other_shadow1 == 1
              "
              class="bgImg"
              src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_HaiLuo/item-exchanged.png"
              :alt="item.alt"
              style="position: absolute; left: 0; z-index: 1; height: 100%"
            />
            <img
              v-else-if="
                exchangedItems &&
                exchangedItems.includes(item.item_name) == false &&
                is_other_shadow1 == 1
              "
              class="bgImg"
              src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_HaiLuo/other-item-exchanged.png"
              :alt="item.alt"
              style="position: absolute; left: 0; z-index: 1; height: 100%"
            />

            <div class="item-top">
              <img :src="item.item_icon" :alt="item.topAlt" />
            </div>
            <div class="item-bottom">
              <span>{{ item.item_name }}</span>
              <span>剩余{{ item.item_count }}份</span>
            </div>
          </div>
        </div>
      </div>

      <div class="page-fuli1">
        <div class="fuli1-title">
          <img
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_HaiLuo/fuli2-title.png"
            alt="福利二标题"
          />
        </div>
        <div class="fuli1-list">
          <div
            class="item"
            v-for="item in secondList"
            :key="item.id"
            @click="claimItem('second', item)"
          >
            <img
              class="bgImg"
              src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_HaiLuo/item-bg.png"
              :alt="item.alt"
            />

            <!-- 已经领取的权益应用的样式 -->
            <img
              v-if="
                exchangedItems &&
                exchangedItems.includes(item.item_name) &&
                is_other_shadow2 == 1
              "
              class="bgImg"
              src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_HaiLuo/item-exchanged.png"
              :alt="item.alt"
              style="position: absolute; left: 0; z-index: 1; height: 100%"
            />
            <img
              v-else-if="
                exchangedItems &&
                exchangedItems.includes(item.item_name) == false &&
                is_other_shadow2 == 1
              "
              class="bgImg"
              src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_HaiLuo/other-item-exchanged.png"
              :alt="item.alt"
              style="position: absolute; left: 0; z-index: 1; height: 100%"
            />

            <div class="item-top">
              <img :src="item.item_icon" :alt="item.topAlt" />
            </div>
            <div class="item-bottom">
              <span>{{ item.item_name }}</span>
              <span>剩余{{ item.item_count }}份</span>
            </div>
          </div>
        </div>
      </div>

      <div class="page-fuli1">
        <div class="fuli1-title">
          <img
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_HaiLuo/fuli3-title.png"
            alt="福利三标题"
          />
        </div>
        <div class="fuli3-content" v-for="good in thirdList">
          <img
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_HaiLuo/fuli3-bg.png"
            alt="福利三背景图"
          />
          <div class="content-left">
            <img :src="good.item_icon" alt="福利三话费券" />
            <div class="content-left-right">
              <span>{{ good.item_name }}</span>
              <span>领取起30天内有效，单笔支付金额大于或等于5.05元可用</span>
            </div>
          </div>
          <div class="content-right">
            <button
              v-if="exchangedItems && exchangedItems.includes(good.item_name)"
              class="finished"
              disabled
            >
              已领取
            </button>
            <button v-else class="unFinished" @click="claimItem('third', good)">
              立即领取
            </button>
          </div>
        </div>
      </div>

      <!-- 领取页面底部弹窗 如果点击该内容之外的其他区域也要隐藏弹窗-->
      <div
        v-if="showModal"
        class="modal-container"
        @click="handleOutsideClick($event)"
      >
        <div class="modal-content">
          <img
            @click="closeModal()"
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_HaiLuo/modalClose.png"
            alt="modalClose"
          />
          <div class="modal-title">权益领取</div>
          <div class="modal-item">
            <span>所选权益</span>
            <span>{{ current_item }}</span>
          </div>

          <div class="modal-slice"></div>

          <div class="modal-item">
            <span>领取号码</span>
            <span>{{ user_telephone }}</span>
          </div>
          <button @click="finalSubmit()">立即领取</button>
        </div>
      </div>

      <div class="footer" style="height: 60px"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      token: "",
      firstList: [],
      secondList: [],
      thirdList: [],
      thirdItem: {
        name: "5元话费券",
        description: "领取起30天内有效，单笔支付金额大于或等于5.05元可用",
      },
      // 用户兑换过的权益 包含n选1_1和n选1_2
      exchangedItems: [],
      exchangedItems_first: [],
      exchangedItems_second: [],
      // n选1_1:如果已经领取了,其他的权益要置灰色
      is_other_shadow1: 0,
      // n选1_2:如果已经领取了,其他的权益要置灰色
      is_other_shadow2: 0,
      showModal: false,
      user_telephone: "",
      // 用户选择当前的权益
      current_item: "",
    };
  },
  mounted() {
    this.getItemInfo();
    // this.token = localStorage.getItem("token");
    // this.getUserInfo();
  },
  methods: {
    // 获取用户信息 领过的权益变成已领取
    getUserInfo() {
      fetch(
        "https://huanxinxiang.ai-me.cn/huanxinxiang-service/v1/get-user/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "App-Name": "huanxinxiang-hailuo",
            token: this.token,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.code == 0) {
            this.userInfo = data.user_info;
            this.user_telephone = this.userInfo.phone_number;
            this.exchangedItems = this.userInfo.exchanged_items["随心享会员"];

            this.firstList.forEach((item) => {
              if (
                this.exchangedItems &&
                this.exchangedItems.includes(item.item_name)
              ) {
                this.is_other_shadow1 = 1;
                console.log(
                  "n选1_1:其他权益是否要置灰色 = ",
                  this.is_other_shadow1
                );
              }
            });
            this.secondList.forEach((item) => {
              if (
                this.exchangedItems &&
                this.exchangedItems.includes(item.item_name)
              ) {
                this.is_other_shadow2 = 1;
                console.log(
                  "n选1_2:其他权益是否要置灰色 = ",
                  this.is_other_shadow2
                );
              }
            });
          } else {
            // 跳转到登录页面
            this.$router.push({ path: "/monthlyHotTopicsNTo2_HaiLuo/receive/login" });
          }
        })
        .catch((error) => {
          console.error("获取用户信息失败:", error);
        });
    },

    getItemInfo() {
      fetch(
        "https://huanxinxiang.ai-me.cn/huanxinxiang-service/v1/get-item/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "App-Name": "huanxinxiang-hailuo",
          },
          body: JSON.stringify({
            item_require: "随心享会员",
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.code == 0) {
            data.item.forEach((item) => {
              if (item.item_exchange_type === "n选1_1") {
                this.firstList.push(item);
              } else if (item.item_exchange_type === "n选1_2") {
                this.secondList.push(item);
              } else if (item.item_exchange_type === "n") {
                this.thirdList.push(item);
              }
            });
            console.log("firstList:", this.firstList);
            console.log("secondList:", this.secondList);
            console.log("thirdList:", this.thirdList);
          } else {
            console.log("后端返回不是0");
          }
        })
        .catch((error) => {
          console.error("获取商品信息失败:", error);
        });
    },
    // 打开弹窗
    claimItem(index, item) {
      console.log("第几个列表 = ", index);
      console.log("选择的权益的全部信息 = ", item);

      console.log("this.is_other_shadow1 = ", this.is_other_shadow1);
      console.log("this.is_other_shadow2 = ", this.is_other_shadow2);
      // 测试用
      // this.showModal = true;
      // this.current_item = item.item_name
      // this.finalSubmit(item.item_name)

      // first列表 当is_other_shadow1为0的时候 领取权益
      if (index == "first" && this.is_other_shadow1 == 0) {
        // 打开底部弹窗
        this.showModal = true;
        console.log(this.showModal);
        this.current_item = item.item_name;
        // this.finalSubmit(item.item_name)
      } else if (index == "second" && this.is_other_shadow2 == 0) {
        // second列表 当is_other_shadow2为0的时候 领取权益
        // 打开底部弹窗
        this.showModal = true;
        this.current_item = item.item_name;
        // this.finalSubmit(item.item_name)
      } else if (index == "third") {
        // 第三个列表的直接领取 不需要底部弹窗
        this.buyItem(item.item_name);
      }
    },

    // 点击弹窗里面的领取按钮
    finalSubmit() {
      this.buyItem(this.current_item);
    },

    // 领取权益
    buyItem(itemName) {
      fetch(
        "https://huanxinxiang.ai-me.cn/huanxinxiang-service/v1/buy-item/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "App-Name": "huanxinxiang-hailuo",
            token: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            item_name: itemName,
            item_require: "随心享会员",
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.code == 0) {
            console.log("领取权益成功返回 = ", data);
            alert(data.message);
            // 领取成功 再获取一次用户信息
            this.getUserInfo();
            this.showModal = false;
          } else {
            console.log("后端返回不是0");
            alert(data.message);
            this.showModal = false;
          }
        })
        .catch((error) => {
          console.error("领取权益失败:", error);
        });
    },
    // 关闭领取权益弹窗
    closeModal() {
      this.showModal = false;
    },
    // 如果点击内容之外的其他区域也要隐藏弹窗
    handleOutsideClick(event) {
      // 检查点击事件的目标是否在模态框内部
      if (!event.target.closest(".modal-content")) {
        // 如果不是，则执行关闭模态框的操作
        this.closeModal();
      }
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  width: 100vw;
  height: 100vh;
}

.main {
}

.page-content {
  background: radial-gradient(
    ellipse 100% 100px at top center,
    transparent 70%,
    #fff 0
  );
  margin-top: -100px;
  position: absolute;
  z-index: 1;
  padding: 0 30px 0 30px;
}

/* 顶部部分start */
.page-header {
  width: 100%;
  padding: 0 20px 0 20px;
}

.page-header img {
  width: 100%;
}

/* 顶部部分end */

/* 分隔线部分start */
.page-slice {
  background-image: url("https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_HaiLuo/content-slice.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-top: 100px;
}

.page-slice img {
  width: 108px;
  height: 8px;

  @media screen and (min-width: 1200px) {
    width: 100%;
    height: 100%;
  }
}

.page-slice span {
  white-space: nowrap;
  padding: 0 18px;
  font-size: 30px;
}

/* 分割线部分end */

/* 福利一部分start */
.page-fuli1 {
  width: 100%;
  color: #694126;
  margin-top: 30px;
}

.page-fuli1 .fuli1-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.page-fuli1 .fuli1-title img {
  width: 288px;
}

.fuli1-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: justify;
}

.fuli1-list:after {
  content: "";
  width: 32%;
}

.fuli1-list .item {
  width: 216px;
  /* width: calc(30% - 10px); */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1% 0;
  /* width: 216px;
		height: 276px; */
  /* background: #FFFFFF;
		box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.06);
		border-radius: 18px;
		border: 2px solid;
		opacity: 0.5;
		border-image: linear-gradient(180deg, rgba(255, 225, 198, 1), rgba(255, 240, 226, 1)) 2 2; */
}

.fuli1-list .item .item-top {
  position: absolute;
  top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 1200px) {
    width: 100%;
  }
}

.fuli1-list .item .item-top img {
  width: 55%;
}

.fuli1-list .item .bgImg {
  width: 100%;
}

.fuli1-list .item .item-bottom {
  position: absolute;
  bottom: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fuli1-list .item .item-bottom span:nth-child(1) {
  font-size: 26px;

  color: #333333;
  /* padding-bottom: 10%; */
}

.fuli1-list .item .item-bottom span:nth-child(2) {
  font-size: 22px;
  color: #999999;
}

/* 福利一部分end */

/* 福利三start */
.fuli3-content {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fuli3-content img {
  width: 100%;
}

.fuli3-content .content-left {
  position: absolute;
  left: 0;
  display: flex;
  padding-left: 30px;
  align-items: center;
  /* height: 80%; */
}

.fuli3-content .content-left img {
  width: 102px;
  height: 96px;
  /* display: flex;
  align-items: center; */

  /* @media screen and (min-width: 1200px) {
    width: 100%;
  } */
}

.fuli3-content .content-left .content-left-right {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 330px;
  margin-left: 10px;
}

.fuli3-content .content-left .content-left-right span:nth-child(1) {
  font-weight: 600;
  font-size: 36px;
  color: #ff5000;
  white-space: nowrap;
}

.fuli3-content .content-left .content-left-right span:nth-child(2) {
  font-weight: 400;
  font-size: 22px;
  color: #888888;
  margin-top: 8px;
}

.content-right {
  position: absolute;
  right: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 62px;
}

.content-right .unFinished {
  width: 146px;
  height: 52px;
  line-height: 52px;
  background: #ff7c00;
  border-radius: 27px;
  border: none;
  font-size: 24px;
  color: #ffffff;
}

.content-right .finished {
  width: 80px;
  height: 30px;
  border-radius: 27px;
  border: none;
  font-size: 14px;
  color: #ffffff;
  background-color: #ccc;

  @media screen and (min-width: 1200px) {
    font-size: 400%;
    width: 50%;
    height: 100%;
    border-radius: 200px;
  }
}

/* 福利三end */

/* 底部领取权益弹窗begin */
.modal-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.modal-content {
  background-color: #fff;
  padding: 0 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content img {
  position: absolute;
  right: 2%;
  top: 5%;
  width: 6%;

  @media screen and (min-width: 768px) {
    right: 0%;
    top: 0%;
    width: 3%;
  }
}

.modal-content .modal-title {
  height: 48px;
  font-weight: 600;
  font-size: 34px;
  color: #333333;
  line-height: 48px;
  margin: 88px 0 75px 0;
}

.modal-content .modal-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.modal-content .modal-item span:nth-child(1) {
  height: 42px;
  font-weight: 400;
  font-size: 30px;
  color: #888888;
  line-height: 42px;
}

.modal-content .modal-item span:nth-child(2) {
  height: 42px;
  font-weight: 400;
  font-size: 30px;
  color: #333333;
  line-height: 42px;
}

.modal-content .modal-slice {
  width: 100%;
  height: 1px;
  background-color: #e3e3e3;
  margin: 21px 0 30px;
}

.modal-content button {
  width: 650px;
  height: 88px;
  background: linear-gradient(335deg, #ff7c00 0%, #ff5000 100%);
  border-radius: 44px;
  border: none;
  font-size: 32px;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  margin: 0 0 130px 0;
}

/* 底部领取权益弹窗end */
</style>
